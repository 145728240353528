import { Operation } from '@camino-solutions/share/proto/common/auth';

export abstract class AccessRule {
  readonly #operation: Operation['value'];

  get operation() {
    return this.#operation;
  }

  protected constructor(operation: Operation['value']) {
    this.#operation = operation;
  }
}
