import {
  GRPC_SELF_SERVICE_CLIENT_SETTINGS,
  ProfileRequest,
  ProfileResponse,
  SelfServiceClient,
} from '@camino-solutions/share/proto/common/auth';
import { Inject, Injectable, Optional } from '@angular/core';
import { GrpcClientFactory, GrpcMetadata } from '@ngx-grpc/common';
import { Observable, of } from 'rxjs';
import { GRPC_CLIENT_FACTORY, GrpcHandler } from '@ngx-grpc/core';
import { environment, isMockAuth } from '@camino-solutions/core/environment';
import { ImprobableEngGrpcWebClientSettings } from '@ngx-grpc/improbable-eng-grpc-web-client';
import { grpc } from '@improbable-eng/grpc-web';

const xhr = grpc.CrossBrowserHttpTransport({});

// TODO tobbinel alkalmazni?
@Injectable({
  providedIn: 'root',
  useFactory: (clientFactory: GrpcClientFactory<never>, handler: GrpcHandler) => {
    return new SelfService(
      {
        host: environment.urls.common.self,
        transport: {
          unary: xhr,
          serverStream: xhr,
          // We not use clientStream and bidiStream
          clientStream: xhr,
          bidiStream: xhr,
        },
      } satisfies ImprobableEngGrpcWebClientSettings,
      clientFactory,
      handler,
    );
  },
  deps: [GRPC_CLIENT_FACTORY, GrpcHandler],
})
export class SelfService extends SelfServiceClient {
  constructor(
    @Optional()
    @Inject(GRPC_SELF_SERVICE_CLIENT_SETTINGS)
    settings: ImprobableEngGrpcWebClientSettings,
    @Inject(GRPC_CLIENT_FACTORY) clientFactory: GrpcClientFactory<never>,
    handler: GrpcHandler,
  ) {
    super(settings, clientFactory, handler);
  }

  override getProfile(
    requestData: ProfileRequest,
    requestMetadata: GrpcMetadata = new GrpcMetadata(),
  ): Observable<ProfileResponse> {
    if (isMockAuth()) {
      return of(new ProfileResponse());
    }
    return super.getProfile(requestData, requestMetadata);
  }
}
