import { inject, Injectable } from '@angular/core';
import { AuthService } from '@camino-solutions/core/auth/service';
import { Operation, ProfileRequest, RoleInfo } from '@camino-solutions/share/proto/common/auth';
import {
  Connectable,
  connectable,
  lastValueFrom,
  map,
  Observable,
  ReplaySubject,
  switchMap,
  take,
} from 'rxjs';
import { environment } from '@camino-solutions/core/environment';
import { User } from './model/user';
import { TestRule } from '@camino-solutions/core/access-rule';
import { RemoveSplashAction } from '@camino-solutions/core/app/state';
import { Store } from '@ngxs/store';
import { SelfService } from './self.service';

let init = false;

@Injectable({ providedIn: 'root' })
export class UserService {
  readonly #store = inject(Store);
  readonly #selfService = inject(SelfService);
  readonly #authService = inject(AuthService);
  // readonly #userSubject$ = new BehaviorSubject<User | null>(null);
  #user$!: Connectable<User>;
  readonly #operations = new Map<Operation['value'], Operation>();

  get user$(): Observable<User> {
    return this.#user$;
  }

  initApp() {
    if (init === true) {
      throw '[UserService::initApp] duplicate call';
    }
    init = true;
    this.#user$ = connectable(
      // megvarjuk az auth-t
      this.#authService.canActivateProtectedRoutes$.pipe(
        take(1),
        // betoltjuk a profile-t
        switchMap(() => {
          // TODO create self service

          return this.#selfService.getProfile(
            new ProfileRequest({ clientId: environment.auth.clientId }),
          );
        }),
        map(profile => {
          // osszekotjuk az ngx permissions-vel
          this.#flatRawOperationCodes(profile.roles ?? []);
          // this.#operations.set(TestRule.instance.operation, new Operation({ value: TestRule.instance.operation ,executable: true}));
          this.#operations.set(
            TestRule.instance.operation,
            new Operation({
              value: TestRule.instance.operation,
              executable: false /*advertisment: true*/,
            }),
          );

          this.#store.dispatch(new RemoveSplashAction());

          return {
            profile,
            userInfo: this.#authService.identityClaims,
          } satisfies User;
        }),
      ),
      {
        connector: () => new ReplaySubject<User>(1),
        resetOnDisconnect: false,
      },
    );
    this.#user$.connect();

    return lastValueFrom(this.#user$.pipe(take(1)));
  }

  hasOperation(operation: Operation['value']): boolean {
    return this.#operations.has(operation);
  }

  isExecutableOperation(operation: Operation['value']): boolean {
    // _log('isExecutableOperation: $operation => ${operations[operation]!.executable}');
    if (this.hasOperation(operation) === false) {
      throw 'Check has operation before use isExecutableOperation';
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.#operations.get(operation)!.executable;
  }

  isAdvertisementOperation(operation: Operation['value']): boolean {
    if (this.hasOperation(operation) === false) {
      throw 'Check has operation before use isExecutableOperation';
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.#operations.get(operation)!.advertisment;
  }

  #flatRawOperationCodes(roles: RoleInfo[]): void {
    roles.forEach(role =>
      role.operations?.forEach(operation => this.#operations.set(operation.value, operation)),
    );
  }
}
