import { AccessRule } from './abstract-rule-access';

export class TestRule extends AccessRule {
  static #instance: TestRule;

  static get instance(): TestRule {
    if (TestRule.#instance === undefined) {
      TestRule.#instance = new TestRule();
    }
    return TestRule.#instance;
  }

  constructor() {
    super(111);
    TestRule.#instance = this;
  }
}
