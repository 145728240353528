/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
//
// THIS IS A GENERATED FILE
// DO NOT MODIFY IT! YOUR CHANGES WILL BE LOST
import { GrpcMessage, RecursivePartial, ToProtobufJSONOptions } from '@ngx-grpc/common';
import { BinaryReader, BinaryWriter, ByteSource } from 'google-protobuf';
import * as registration000 from '../../common/services/configurations.pb';

/**
 * Message implementation for profiles.ProfileRequest
 */
export class ProfileRequest implements GrpcMessage {
  static id = 'profiles.ProfileRequest';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ProfileRequest();
    ProfileRequest.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ProfileRequest) {
    _instance.environmentType = _instance.environmentType || 0;
    _instance.clientId = _instance.clientId || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: ProfileRequest, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.email = _reader.readString();
          break;
        case 2:
          _instance.profileId = _reader.readString();
          break;
        case 3:
          _instance.userId = _reader.readString();
          break;
        case 4:
          _instance.environmentType = _reader.readEnum();
          break;
        case 5:
          _instance.clientId = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    ProfileRequest.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: ProfileRequest, _writer: BinaryWriter) {
    if (_instance.email || _instance.email === '') {
      _writer.writeString(1, _instance.email);
    }
    if (_instance.profileId || _instance.profileId === '') {
      _writer.writeString(2, _instance.profileId);
    }
    if (_instance.userId || _instance.userId === '') {
      _writer.writeString(3, _instance.userId);
    }
    if (_instance.environmentType) {
      _writer.writeEnum(4, _instance.environmentType);
    }
    if (_instance.clientId) {
      _writer.writeString(5, _instance.clientId);
    }
  }

  private _email: string;
  private _profileId: string;
  private _userId: string;
  private _environmentType: registration000.EnvironmentType;
  private _clientId: string;

  private _filter: ProfileRequest.FilterCase = ProfileRequest.FilterCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ProfileRequest to deeply clone from
   */
  constructor(_value?: RecursivePartial<ProfileRequest.AsObject>) {
    _value = _value || {};
    this.email = _value.email;
    this.profileId = _value.profileId;
    this.userId = _value.userId;
    this.environmentType = _value.environmentType;
    this.clientId = _value.clientId;
    ProfileRequest.refineValues(this);
  }
  get email(): string {
    return this._email;
  }
  set email(value: string) {
    if (value !== undefined && value !== null) {
      this._profileId = this._userId = undefined;
      this._filter = ProfileRequest.FilterCase.email;
    }
    this._email = value;
  }
  get profileId(): string {
    return this._profileId;
  }
  set profileId(value: string) {
    if (value !== undefined && value !== null) {
      this._email = this._userId = undefined;
      this._filter = ProfileRequest.FilterCase.profileId;
    }
    this._profileId = value;
  }
  get userId(): string {
    return this._userId;
  }
  set userId(value: string) {
    if (value !== undefined && value !== null) {
      this._email = this._profileId = undefined;
      this._filter = ProfileRequest.FilterCase.userId;
    }
    this._userId = value;
  }
  get environmentType(): registration000.EnvironmentType {
    return this._environmentType;
  }
  set environmentType(value: registration000.EnvironmentType) {
    this._environmentType = value;
  }
  get clientId(): string {
    return this._clientId;
  }
  set clientId(value: string) {
    this._clientId = value;
  }
  get filter() {
    return this._filter;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ProfileRequest.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ProfileRequest.AsObject {
    return {
      email: this.email,
      profileId: this.profileId,
      userId: this.userId,
      environmentType: this.environmentType,
      clientId: this.clientId,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions,
  ): ProfileRequest.AsProtobufJSON {
    return {
      email: this.email === null || this.email === undefined ? null : this.email,
      profileId: this.profileId === null || this.profileId === undefined ? null : this.profileId,
      userId: this.userId === null || this.userId === undefined ? null : this.userId,
      environmentType:
        registration000.EnvironmentType[
          this.environmentType === null || this.environmentType === undefined
            ? 0
            : this.environmentType
        ],
      clientId: this.clientId,
    };
  }
}
export module ProfileRequest {
  /**
   * Standard JavaScript object representation for ProfileRequest
   */
  export interface AsObject {
    email: string;
    profileId: string;
    userId: string;
    environmentType: registration000.EnvironmentType;
    clientId: string;
  }

  /**
   * Protobuf JSON representation for ProfileRequest
   */
  export interface AsProtobufJSON {
    email: string | null;
    profileId: string | null;
    userId: string | null;
    environmentType: string;
    clientId: string;
  }
  export enum FilterCase {
    none = 0,
    email = 1,
    profileId = 2,
    userId = 3,
  }
}

/**
 * Message implementation for profiles.Operation
 */
export class Operation implements GrpcMessage {
  static id = 'profiles.Operation';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new Operation();
    Operation.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: Operation) {
    _instance.value = _instance.value || 0;
    _instance.code = _instance.code || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: Operation, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.value = _reader.readInt32();
          break;
        case 2:
          _instance.code = _reader.readString();
          break;
        case 3:
          _instance.executable = _reader.readBool();
          break;
        case 4:
          _instance.advertisment = _reader.readBool();
          break;
        default:
          _reader.skipField();
      }
    }

    Operation.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: Operation, _writer: BinaryWriter) {
    if (_instance.value) {
      _writer.writeInt32(1, _instance.value);
    }
    if (_instance.code) {
      _writer.writeString(2, _instance.code);
    }
    if (_instance.executable || _instance.executable === false) {
      _writer.writeBool(3, _instance.executable);
    }
    if (_instance.advertisment || _instance.advertisment === false) {
      _writer.writeBool(4, _instance.advertisment);
    }
  }

  private _value: number;
  private _code: string;
  private _executable: boolean;
  private _advertisment: boolean;

  private _availability: Operation.AvailabilityCase = Operation.AvailabilityCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of Operation to deeply clone from
   */
  constructor(_value?: RecursivePartial<Operation.AsObject>) {
    _value = _value || {};
    this.value = _value.value;
    this.code = _value.code;
    this.executable = _value.executable;
    this.advertisment = _value.advertisment;
    Operation.refineValues(this);
  }
  get value(): number {
    return this._value;
  }
  set value(value: number) {
    this._value = value;
  }
  get code(): string {
    return this._code;
  }
  set code(value: string) {
    this._code = value;
  }
  get executable(): boolean {
    return this._executable;
  }
  set executable(value: boolean) {
    if (value !== undefined && value !== null) {
      this._advertisment = undefined;
      this._availability = Operation.AvailabilityCase.executable;
    }
    this._executable = value;
  }
  get advertisment(): boolean {
    return this._advertisment;
  }
  set advertisment(value: boolean) {
    if (value !== undefined && value !== null) {
      this._executable = undefined;
      this._availability = Operation.AvailabilityCase.advertisment;
    }
    this._advertisment = value;
  }
  get availability() {
    return this._availability;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    Operation.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): Operation.AsObject {
    return {
      value: this.value,
      code: this.code,
      executable: this.executable,
      advertisment: this.advertisment,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions,
  ): Operation.AsProtobufJSON {
    return {
      value: this.value,
      code: this.code,
      executable: this.executable,
      advertisment: this.advertisment,
    };
  }
}
export module Operation {
  /**
   * Standard JavaScript object representation for Operation
   */
  export interface AsObject {
    value: number;
    code: string;
    executable: boolean;
    advertisment: boolean;
  }

  /**
   * Protobuf JSON representation for Operation
   */
  export interface AsProtobufJSON {
    value: number;
    code: string;
    executable: boolean;
    advertisment: boolean;
  }
  export enum AvailabilityCase {
    none = 0,
    executable = 1,
    advertisment = 2,
  }
}

/**
 * Message implementation for profiles.RoleInfo
 */
export class RoleInfo implements GrpcMessage {
  static id = 'profiles.RoleInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new RoleInfo();
    RoleInfo.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: RoleInfo) {
    _instance.roleId = _instance.roleId || '';
    _instance.name = _instance.name || '';
    _instance.operations = _instance.operations || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: RoleInfo, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.roleId = _reader.readString();
          break;
        case 2:
          _instance.name = _reader.readString();
          break;
        case 3:
          const messageInitializer3 = new Operation();
          _reader.readMessage(messageInitializer3, Operation.deserializeBinaryFromReader);
          (_instance.operations = _instance.operations || []).push(messageInitializer3);
          break;
        default:
          _reader.skipField();
      }
    }

    RoleInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: RoleInfo, _writer: BinaryWriter) {
    if (_instance.roleId) {
      _writer.writeString(1, _instance.roleId);
    }
    if (_instance.name) {
      _writer.writeString(2, _instance.name);
    }
    if (_instance.operations && _instance.operations.length) {
      _writer.writeRepeatedMessage(
        3,
        _instance.operations as any,
        Operation.serializeBinaryToWriter,
      );
    }
  }

  private _roleId: string;
  private _name: string;
  private _operations?: Operation[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of RoleInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<RoleInfo.AsObject>) {
    _value = _value || {};
    this.roleId = _value.roleId;
    this.name = _value.name;
    this.operations = (_value.operations || []).map(m => new Operation(m));
    RoleInfo.refineValues(this);
  }
  get roleId(): string {
    return this._roleId;
  }
  set roleId(value: string) {
    this._roleId = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get operations(): Operation[] | undefined {
    return this._operations;
  }
  set operations(value: Operation[] | undefined) {
    this._operations = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    RoleInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): RoleInfo.AsObject {
    return {
      roleId: this.roleId,
      name: this.name,
      operations: (this.operations || []).map(m => m.toObject()),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions,
  ): RoleInfo.AsProtobufJSON {
    return {
      roleId: this.roleId,
      name: this.name,
      operations: (this.operations || []).map(m => m.toProtobufJSON(options)),
    };
  }
}
export module RoleInfo {
  /**
   * Standard JavaScript object representation for RoleInfo
   */
  export interface AsObject {
    roleId: string;
    name: string;
    operations?: Operation.AsObject[];
  }

  /**
   * Protobuf JSON representation for RoleInfo
   */
  export interface AsProtobufJSON {
    roleId: string;
    name: string;
    operations: Operation.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for profiles.ProfileInfo
 */
export class ProfileInfo implements GrpcMessage {
  static id = 'profiles.ProfileInfo';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ProfileInfo();
    ProfileInfo.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ProfileInfo) {
    _instance.profileId = _instance.profileId || '';
    _instance.accountId = _instance.accountId || '';
    _instance.name = _instance.name || '';
    _instance.email = _instance.email || '';
    _instance.phone = _instance.phone || '';
    _instance.pictureUrl = _instance.pictureUrl || '';
    _instance.emailConfirmed = _instance.emailConfirmed || false;
    _instance.companyName = _instance.companyName || '';
    _instance.role = _instance.role || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: ProfileInfo, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.profileId = _reader.readString();
          break;
        case 2:
          _instance.accountId = _reader.readString();
          break;
        case 3:
          _instance.name = _reader.readString();
          break;
        case 4:
          _instance.email = _reader.readString();
          break;
        case 5:
          _instance.phone = _reader.readString();
          break;
        case 6:
          _instance.pictureUrl = _reader.readString();
          break;
        case 7:
          _instance.emailConfirmed = _reader.readBool();
          break;
        case 8:
          _instance.companyName = _reader.readString();
          break;
        case 9:
          _instance.role = _reader.readString();
          break;
        case 10:
          _instance.active = _reader.readBool();
          break;
        case 11:
          _instance.available = _reader.readBool();
          break;
        case 20:
          _instance.self = _reader.readBool();
          break;
        case 21:
          _instance.substitutes = new ProfileInfo();
          _reader.readMessage(_instance.substitutes, ProfileInfo.deserializeBinaryFromReader);
          break;
        case 22:
          _instance.represents = new ProfileInfo();
          _reader.readMessage(_instance.represents, ProfileInfo.deserializeBinaryFromReader);
          break;
        default:
          _reader.skipField();
      }
    }

    ProfileInfo.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: ProfileInfo, _writer: BinaryWriter) {
    if (_instance.profileId) {
      _writer.writeString(1, _instance.profileId);
    }
    if (_instance.accountId) {
      _writer.writeString(2, _instance.accountId);
    }
    if (_instance.name) {
      _writer.writeString(3, _instance.name);
    }
    if (_instance.email) {
      _writer.writeString(4, _instance.email);
    }
    if (_instance.phone) {
      _writer.writeString(5, _instance.phone);
    }
    if (_instance.pictureUrl) {
      _writer.writeString(6, _instance.pictureUrl);
    }
    if (_instance.emailConfirmed) {
      _writer.writeBool(7, _instance.emailConfirmed);
    }
    if (_instance.companyName) {
      _writer.writeString(8, _instance.companyName);
    }
    if (_instance.role) {
      _writer.writeString(9, _instance.role);
    }
    if (_instance.active || _instance.active === false) {
      _writer.writeBool(10, _instance.active);
    }
    if (_instance.available || _instance.available === false) {
      _writer.writeBool(11, _instance.available);
    }
    if (_instance.self || _instance.self === false) {
      _writer.writeBool(20, _instance.self);
    }
    if (_instance.substitutes) {
      _writer.writeMessage(21, _instance.substitutes as any, ProfileInfo.serializeBinaryToWriter);
    }
    if (_instance.represents) {
      _writer.writeMessage(22, _instance.represents as any, ProfileInfo.serializeBinaryToWriter);
    }
  }

  private _profileId: string;
  private _accountId: string;
  private _name: string;
  private _email: string;
  private _phone: string;
  private _pictureUrl: string;
  private _emailConfirmed: boolean;
  private _companyName: string;
  private _role: string;
  private _active: boolean;
  private _available: boolean;
  private _self: boolean;
  private _substitutes?: ProfileInfo;
  private _represents?: ProfileInfo;

  private _state: ProfileInfo.StateCase = ProfileInfo.StateCase.none;
  private _kind: ProfileInfo.KindCase = ProfileInfo.KindCase.none;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ProfileInfo to deeply clone from
   */
  constructor(_value?: RecursivePartial<ProfileInfo.AsObject>) {
    _value = _value || {};
    this.profileId = _value.profileId;
    this.accountId = _value.accountId;
    this.name = _value.name;
    this.email = _value.email;
    this.phone = _value.phone;
    this.pictureUrl = _value.pictureUrl;
    this.emailConfirmed = _value.emailConfirmed;
    this.companyName = _value.companyName;
    this.role = _value.role;
    this.active = _value.active;
    this.available = _value.available;
    this.self = _value.self;
    this.substitutes = _value.substitutes ? new ProfileInfo(_value.substitutes) : undefined;
    this.represents = _value.represents ? new ProfileInfo(_value.represents) : undefined;
    ProfileInfo.refineValues(this);
  }
  get profileId(): string {
    return this._profileId;
  }
  set profileId(value: string) {
    this._profileId = value;
  }
  get accountId(): string {
    return this._accountId;
  }
  set accountId(value: string) {
    this._accountId = value;
  }
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }
  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
  }
  get phone(): string {
    return this._phone;
  }
  set phone(value: string) {
    this._phone = value;
  }
  get pictureUrl(): string {
    return this._pictureUrl;
  }
  set pictureUrl(value: string) {
    this._pictureUrl = value;
  }
  get emailConfirmed(): boolean {
    return this._emailConfirmed;
  }
  set emailConfirmed(value: boolean) {
    this._emailConfirmed = value;
  }
  get companyName(): string {
    return this._companyName;
  }
  set companyName(value: string) {
    this._companyName = value;
  }
  get role(): string {
    return this._role;
  }
  set role(value: string) {
    this._role = value;
  }
  get active(): boolean {
    return this._active;
  }
  set active(value: boolean) {
    if (value !== undefined && value !== null) {
      this._available = undefined;
      this._state = ProfileInfo.StateCase.active;
    }
    this._active = value;
  }
  get available(): boolean {
    return this._available;
  }
  set available(value: boolean) {
    if (value !== undefined && value !== null) {
      this._active = undefined;
      this._state = ProfileInfo.StateCase.available;
    }
    this._available = value;
  }
  get self(): boolean {
    return this._self;
  }
  set self(value: boolean) {
    if (value !== undefined && value !== null) {
      this._substitutes = this._represents = undefined;
      this._kind = ProfileInfo.KindCase.self;
    }
    this._self = value;
  }
  get substitutes(): ProfileInfo | undefined {
    return this._substitutes;
  }
  set substitutes(value: ProfileInfo | undefined) {
    if (value !== undefined && value !== null) {
      this._self = this._represents = undefined;
      this._kind = ProfileInfo.KindCase.substitutes;
    }
    this._substitutes = value;
  }
  get represents(): ProfileInfo | undefined {
    return this._represents;
  }
  set represents(value: ProfileInfo | undefined) {
    if (value !== undefined && value !== null) {
      this._self = this._substitutes = undefined;
      this._kind = ProfileInfo.KindCase.represents;
    }
    this._represents = value;
  }
  get state() {
    return this._state;
  }
  get kind() {
    return this._kind;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ProfileInfo.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ProfileInfo.AsObject {
    return {
      profileId: this.profileId,
      accountId: this.accountId,
      name: this.name,
      email: this.email,
      phone: this.phone,
      pictureUrl: this.pictureUrl,
      emailConfirmed: this.emailConfirmed,
      companyName: this.companyName,
      role: this.role,
      active: this.active,
      available: this.available,
      self: this.self,
      substitutes: this.substitutes ? this.substitutes.toObject() : undefined,
      represents: this.represents ? this.represents.toObject() : undefined,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions,
  ): ProfileInfo.AsProtobufJSON {
    return {
      profileId: this.profileId,
      accountId: this.accountId,
      name: this.name,
      email: this.email,
      phone: this.phone,
      pictureUrl: this.pictureUrl,
      emailConfirmed: this.emailConfirmed,
      companyName: this.companyName,
      role: this.role,
      active: this.active,
      available: this.available,
      self: this.self,
      substitutes: this.substitutes ? this.substitutes.toProtobufJSON(options) : null,
      represents: this.represents ? this.represents.toProtobufJSON(options) : null,
    };
  }
}
export module ProfileInfo {
  /**
   * Standard JavaScript object representation for ProfileInfo
   */
  export interface AsObject {
    profileId: string;
    accountId: string;
    name: string;
    email: string;
    phone: string;
    pictureUrl: string;
    emailConfirmed: boolean;
    companyName: string;
    role: string;
    active: boolean;
    available: boolean;
    self: boolean;
    substitutes?: ProfileInfo.AsObject;
    represents?: ProfileInfo.AsObject;
  }

  /**
   * Protobuf JSON representation for ProfileInfo
   */
  export interface AsProtobufJSON {
    profileId: string;
    accountId: string;
    name: string;
    email: string;
    phone: string;
    pictureUrl: string;
    emailConfirmed: boolean;
    companyName: string;
    role: string;
    active: boolean;
    available: boolean;
    self: boolean;
    substitutes: ProfileInfo.AsProtobufJSON | null;
    represents: ProfileInfo.AsProtobufJSON | null;
  }
  export enum StateCase {
    none = 0,
    active = 1,
    available = 2,
  }
  export enum KindCase {
    none = 0,
    self = 1,
    substitutes = 2,
    represents = 3,
  }
}

/**
 * Message implementation for profiles.ProfileResponse
 */
export class ProfileResponse implements GrpcMessage {
  static id = 'profiles.ProfileResponse';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new ProfileResponse();
    ProfileResponse.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: ProfileResponse) {
    _instance.userId = _instance.userId || '';
    _instance.lcid = _instance.lcid || '';
    _instance.emailConfirmed = _instance.emailConfirmed || false;
    _instance.profiles = _instance.profiles || [];
    _instance.roles = _instance.roles || [];
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: ProfileResponse, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 1:
          _instance.userId = _reader.readString();
          break;
        case 2:
          _instance.lcid = _reader.readString();
          break;
        case 3:
          _instance.emailConfirmed = _reader.readBool();
          break;
        case 4:
          const messageInitializer4 = new ProfileInfo();
          _reader.readMessage(messageInitializer4, ProfileInfo.deserializeBinaryFromReader);
          (_instance.profiles = _instance.profiles || []).push(messageInitializer4);
          break;
        case 5:
          const messageInitializer5 = new RoleInfo();
          _reader.readMessage(messageInitializer5, RoleInfo.deserializeBinaryFromReader);
          (_instance.roles = _instance.roles || []).push(messageInitializer5);
          break;
        default:
          _reader.skipField();
      }
    }

    ProfileResponse.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: ProfileResponse, _writer: BinaryWriter) {
    if (_instance.userId) {
      _writer.writeString(1, _instance.userId);
    }
    if (_instance.lcid) {
      _writer.writeString(2, _instance.lcid);
    }
    if (_instance.emailConfirmed) {
      _writer.writeBool(3, _instance.emailConfirmed);
    }
    if (_instance.profiles && _instance.profiles.length) {
      _writer.writeRepeatedMessage(
        4,
        _instance.profiles as any,
        ProfileInfo.serializeBinaryToWriter,
      );
    }
    if (_instance.roles && _instance.roles.length) {
      _writer.writeRepeatedMessage(5, _instance.roles as any, RoleInfo.serializeBinaryToWriter);
    }
  }

  private _userId: string;
  private _lcid: string;
  private _emailConfirmed: boolean;
  private _profiles?: ProfileInfo[];
  private _roles?: RoleInfo[];

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of ProfileResponse to deeply clone from
   */
  constructor(_value?: RecursivePartial<ProfileResponse.AsObject>) {
    _value = _value || {};
    this.userId = _value.userId;
    this.lcid = _value.lcid;
    this.emailConfirmed = _value.emailConfirmed;
    this.profiles = (_value.profiles || []).map(m => new ProfileInfo(m));
    this.roles = (_value.roles || []).map(m => new RoleInfo(m));
    ProfileResponse.refineValues(this);
  }
  get userId(): string {
    return this._userId;
  }
  set userId(value: string) {
    this._userId = value;
  }
  get lcid(): string {
    return this._lcid;
  }
  set lcid(value: string) {
    this._lcid = value;
  }
  get emailConfirmed(): boolean {
    return this._emailConfirmed;
  }
  set emailConfirmed(value: boolean) {
    this._emailConfirmed = value;
  }
  get profiles(): ProfileInfo[] | undefined {
    return this._profiles;
  }
  set profiles(value: ProfileInfo[] | undefined) {
    this._profiles = value;
  }
  get roles(): RoleInfo[] | undefined {
    return this._roles;
  }
  set roles(value: RoleInfo[] | undefined) {
    this._roles = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    ProfileResponse.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): ProfileResponse.AsObject {
    return {
      userId: this.userId,
      lcid: this.lcid,
      emailConfirmed: this.emailConfirmed,
      profiles: (this.profiles || []).map(m => m.toObject()),
      roles: (this.roles || []).map(m => m.toObject()),
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions,
  ): ProfileResponse.AsProtobufJSON {
    return {
      userId: this.userId,
      lcid: this.lcid,
      emailConfirmed: this.emailConfirmed,
      profiles: (this.profiles || []).map(m => m.toProtobufJSON(options)),
      roles: (this.roles || []).map(m => m.toProtobufJSON(options)),
    };
  }
}
export module ProfileResponse {
  /**
   * Standard JavaScript object representation for ProfileResponse
   */
  export interface AsObject {
    userId: string;
    lcid: string;
    emailConfirmed: boolean;
    profiles?: ProfileInfo.AsObject[];
    roles?: RoleInfo.AsObject[];
  }

  /**
   * Protobuf JSON representation for ProfileResponse
   */
  export interface AsProtobufJSON {
    userId: string;
    lcid: string;
    emailConfirmed: boolean;
    profiles: ProfileInfo.AsProtobufJSON[] | null;
    roles: RoleInfo.AsProtobufJSON[] | null;
  }
}

/**
 * Message implementation for profiles.LocaleSettings
 */
export class LocaleSettings implements GrpcMessage {
  static id = 'profiles.LocaleSettings';

  /**
   * Deserialize binary data to message
   * @param instance message instance
   */
  static deserializeBinary(bytes: ByteSource) {
    const instance = new LocaleSettings();
    LocaleSettings.deserializeBinaryFromReader(instance, new BinaryReader(bytes));
    return instance;
  }

  /**
   * Check all the properties and set default protobuf values if necessary
   * @param _instance message instance
   */
  static refineValues(_instance: LocaleSettings) {
    _instance.lcid = _instance.lcid || '';
  }

  /**
   * Deserializes / reads binary message into message instance using provided binary reader
   * @param _instance message instance
   * @param _reader binary reader instance
   */
  static deserializeBinaryFromReader(_instance: LocaleSettings, _reader: BinaryReader) {
    while (_reader.nextField()) {
      if (_reader.isEndGroup()) break;

      switch (_reader.getFieldNumber()) {
        case 2:
          _instance.lcid = _reader.readString();
          break;
        default:
          _reader.skipField();
      }
    }

    LocaleSettings.refineValues(_instance);
  }

  /**
   * Serializes a message to binary format using provided binary reader
   * @param _instance message instance
   * @param _writer binary writer instance
   */
  static serializeBinaryToWriter(_instance: LocaleSettings, _writer: BinaryWriter) {
    if (_instance.lcid) {
      _writer.writeString(2, _instance.lcid);
    }
  }

  private _lcid: string;

  /**
   * Message constructor. Initializes the properties and applies default Protobuf values if necessary
   * @param _value initial values object or instance of LocaleSettings to deeply clone from
   */
  constructor(_value?: RecursivePartial<LocaleSettings.AsObject>) {
    _value = _value || {};
    this.lcid = _value.lcid;
    LocaleSettings.refineValues(this);
  }
  get lcid(): string {
    return this._lcid;
  }
  set lcid(value: string) {
    this._lcid = value;
  }

  /**
   * Serialize message to binary data
   * @param instance message instance
   */
  serializeBinary() {
    const writer = new BinaryWriter();
    LocaleSettings.serializeBinaryToWriter(this, writer);
    return writer.getResultBuffer();
  }

  /**
   * Cast message to standard JavaScript object (all non-primitive values are deeply cloned)
   */
  toObject(): LocaleSettings.AsObject {
    return {
      lcid: this.lcid,
    };
  }

  /**
   * Convenience method to support JSON.stringify(message), replicates the structure of toObject()
   */
  toJSON() {
    return this.toObject();
  }

  /**
   * Cast message to JSON using protobuf JSON notation: https://developers.google.com/protocol-buffers/docs/proto3#json
   * Attention: output differs from toObject() e.g. enums are represented as names and not as numbers, Timestamp is an ISO Date string format etc.
   * If the message itself or some of descendant messages is google.protobuf.Any, you MUST provide a message pool as options. If not, the messagePool is not required
   */
  toProtobufJSON(
    // @ts-ignore
    options?: ToProtobufJSONOptions,
  ): LocaleSettings.AsProtobufJSON {
    return {
      lcid: this.lcid,
    };
  }
}
export module LocaleSettings {
  /**
   * Standard JavaScript object representation for LocaleSettings
   */
  export interface AsObject {
    lcid: string;
  }

  /**
   * Protobuf JSON representation for LocaleSettings
   */
  export interface AsProtobufJSON {
    lcid: string;
  }
}
